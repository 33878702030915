<template>
  <div class="fixed top-0 right-0 left-0 z-20">
    <header class="w-full border-t-4 border-yellow-400 shadow-lg">
      <div class="hidden border-b border-neutral-900 bg-neutral-950 lg:block">
        <div
          class="mx-auto flex w-full max-w-[var(--breakpoint-2xl)] items-center justify-between overflow-x-auto px-6 sm:px-8"
        >
          <UNavigationMenu
            :items="topNavigationMenu"
            variant="link"
            class="-ml-3"
          />
          <div v-if="bannerEnabled" class="hidden w-full text-right lg:block">
            <NuxtLink
              v-if="story.content.banner_link.linktype === 'story'"
              :to="`/${story.content.banner_link.cached_url}`"
              class="text-sm font-medium text-yellow-400 hover:underline"
            >
              {{ story.content.banner_copy }}
            </NuxtLink>
            <NuxtLink
              v-else
              :to="story.content.banner_link.url"
              class="text-sm font-medium text-yellow-400 hover:underline"
            >
              {{ story.content.banner_copy }}
            </NuxtLink>
          </div>
        </div>
      </div>
      <div class="border-b-2 border-neutral-900 bg-black">
        <div
          class="mx-auto w-full max-w-[var(--breakpoint-2xl)] px-6 py-4 sm:px-8"
        >
          <div class="flex items-center">
            <NuxtLink to="/" class="shrink-0">
              <NuxtImg
                provider="storyblok"
                class="h-16 w-auto"
                height="64"
                width="74"
                :src="story.content.site_logo.filename"
                :alt="story.content.site_logo.alt"
                preload
                priority
                loading="eager"
              />
            </NuxtLink>
            <UNavigationMenu
              v-model="active"
              :items="mainNavigationMenu"
              variant="link"
              :ui="{
                link: 'text-md font-semibold px-3.5 py-2.5',
                viewport: 'bg-neutral-900 max-w-[var(--breakpoint-md)]',
              }"
              class="mx-auto hidden justify-center lg:flex"
            />
            <div
              class="ml-auto flex shrink-0 items-center justify-center space-x-2 lg:ml-0"
            >
              <LazyMarketingNavigationSearch />
              <div class="lg:hidden">
                <USlideover
                  v-model:open="mobileOpen"
                  :close="{
                    color: 'primary',
                    variant: 'outline',
                    class: 'rounded-full bg-black z-[1]',
                  }"
                  :ui="{
                    content: 'divide-y-0 bg-neutral-950/90 backdrop-blur-3xl',
                    header: 'p-0 m-0',
                  }"
                >
                  <UButton
                    title="Menu"
                    size="xl"
                    variant="subtle"
                    icon="i-lucide-menu"
                    class="cursor-pointer"
                  />
                  <template #body>
                    <UNavigationMenu
                      v-model="active"
                      :items="mainNavigationMenu"
                      orientation="vertical"
                      :ui="{
                        link: 'text-md font-semibold px-3.5 py-2.5',
                      }"
                    />
                    <UNavigationMenu
                      v-model="active"
                      :items="topNavigationMenu"
                      orientation="vertical"
                      :ui="{
                        link: 'text-md font-semibold px-3.5 py-2.5',
                      }"
                    />
                  </template>
                  <template #footer>
                    <UButton
                      size="xl"
                      class="w-full cursor-pointer justify-center text-center"
                      @click="bookNow"
                    >
                      Book Now
                    </UButton>
                  </template>
                </USlideover>
              </div>
              <UButton
                size="xl"
                class="hidden cursor-pointer lg:block"
                @click="bookNow"
              >
                Book Now
              </UButton>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script setup lang="ts">
const isPreview = useRuntimeConfig().public.NODE_ENV !== 'production';
const mobileOpen = ref(false);

const story = await useAsyncStoryblok(
  'config',
  {
    version: isPreview ? 'draft' : 'published',
    // resolve_relations: 'overview.featured_story',
  }
  // { resolveRelations: 'overview.featured_story' }
);

// Active Navigation Route
const active = ref();

// Navigation Menus
const mainNavigationMenu = ref([]);
const topNavigationMenu = ref([]);

// Format Navigation menu Items
function processMenuData(menuData) {
  return menuData.map((item) => {
    const newItem = {};

    newItem.label = item.label;
    newItem.icon = item.icon;

    if (item.to) {
      let url = '';
      if (item.to.linktype === 'story') {
        url = `/${item.to.cached_url}`;
      } else {
        url = item.to.url;
      }
      // Remove trailing slashes
      url = url.replace(/\/+$/, '');
      newItem.to = url;
    }

    if (item.description) {
      newItem.description = item.description;
    }

    if (item.children && item.children.length > 0) {
      newItem.children = processMenuData(item.children);
    }

    return newItem;
  });
}

if (story.value && story.value.content) {
  mainNavigationMenu.value = processMenuData(story.value.content.main_nav);
  topNavigationMenu.value = processMenuData(story.value.content.top_nav);
}

const route = useRoute();
const router = useRouter();

const bookNow = () => {
  if (route.path.includes('/activities/')) {
    router.replace({
      query: {
        ...route.query,
        tab: 'availability',
      },
    });
  } else if (route.path.includes('/events/')) {
    router.replace({
      query: {
        ...route.query,
        tab: 'rsvp',
      },
    });
  } else if (route.path.includes('/groups/')) {
    router.replace({
      query: {
        ...route.query,
        tab: 'packages',
      },
    });
  } else {
    navigateTo('/book');
  }
};

const bannerEnabled = computed(() => {
  const currentDate = new Date();
  const startDate = new Date(story.value.content.start_date);
  const endDate = new Date(story.value.content.end_date);

  if (startDate > currentDate || endDate < currentDate) {
    return false;
  } else {
    return true;
  }
});

// Watch for route changes to close the mobile menu
watch(
  () => route.path,
  () => {
    mobileOpen.value = false;
  }
);
</script>
